import firebase from '@firebase/app'
import '@firebase/firestore'
import '@firebase/auth'
import '@firebase/functions'
import '@firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyC15qxz3GbM10V9rxEcDM1JdqLpamvDdAM',
  authDomain: 'www.amborjo.com',
  databaseURL: 'https://moonhope-174701.firebaseio.com',
  projectId: 'moonhope-174701',
  storageBucket: 'moonhope-174701.appspot.com',
  messagingSenderId: '740817662814',
  appId: '1:740817662814:web:a282f9da8e99a7b95dd5cf'
}

firebase.initializeApp(firebaseConfig)

firebase.firestore().enablePersistence()
  .catch(e => {
    if (e.code === 'failed-precondition') {
      // console.error('Something went wrong')
    } else if (e.code === 'unimplemented') {
      // console.error('Something went wrong)
    }
  })

export default firebase
