<template>
  <v-list-item to="/management/restaurants/">
    <v-list-item-icon v-if="loading">
      <v-progress-circular indeterminate />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-if="!loading" v-text="`${restoCount} restaurant${restoCount > 1 ? 's' : ''}`" />
      <v-list-item-subtitle>Manage your restaurants</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import firebase from '../../../plugins/firebase'

export default {
  name: 'RestaurantManagementLink',

  computed: {
    uid () { return this.$store.getters['auth/user'] ? this.$store.getters['auth/user'].uid : null }
  },

  data () {
    return ({
      loading: false,
      restoCount: null
    })
  },

  methods: {
    getRestaurantCount () {
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('/restaurants').where('author', '==', this.uid).get()
          .then(snap => { resolve(snap.size) })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true
    this.$data.restoCount = await this.getRestaurantCount()
    this.$data.loading = false
  }
}
</script>
