export default {
  namespaced: true,
  state: {
    displayWelcome: false,
    newlyCreatedRestaurantId: null
  },
  getters: {
    displayWelcome (state) {
      return state.displayWelcome
    },
    newlyCreatedRestaurantId (state) {
      return state.newlyCreatedRestaurantId
    }
  },
  mutations: {
    setNewlyCreatedRestaurantId (state, v) {
      state.newlyCreatedRestaurantId = v
    },
    setDisplayWelcome (state, v) {
      state.displayWelcome = v
    }
  }
}
