export default [
  {
    path: '/management/restaurants/',
    name: 'RestaurantManagement',
    component: () => import('../views/ManageRestaurants.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/management/restaurants/:restaurantId',
    name: 'ManageARestaurant',
    component: () => import('../views/Restaurants/RestaurantManage/RestaurantManage2.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/management/new-restaurant/:step',
    name: 'NewRestaurant',
    component: () => import('../views/Restaurants/NewRestaurant/RestaurantNew.vue'),
    props: (route) => {
      const props = { ...route.params }
      props.step = parseInt(props.step)
      return props
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/management/new-restaurant',
    redirect: '/management/new-restaurant/1',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/management/restaurants/:restaurantId/basic-info',
    name: 'RestaurantBasicInfoEditor',
    component: () => import('../views/Restaurants/RestaurantManage/BasicInfo'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/management/restaurants/:restaurantId/menu',
    name: 'RestaurantMenuEditor',
    component: () => import('../views/Restaurants/RestaurantManage/Menu'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/management/restaurants/:restaurantId/gallery',
    name: 'RestaurantGalleryManagement',
    component: () => import('../views/Restaurants/RestaurantManage/Gallery'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/management/restaurants/:restaurantId/business-hours',
    name: 'RestaurantBusinessHourManagement',
    component: () => import('../views/Restaurants/RestaurantManage/OpeningHour'),
    props: true,
    meta: {
      requiresAuth: true
    }
  }
]
