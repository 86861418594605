<template>
  <v-snackbar
    v-model="displayNotification"
    :color="notification ? notification.color : null"
  >
    {{ notification ? notification.content : null }}
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return ({
      // displayNotification: false
    })
  },
  computed: {
    notification () {
      return this.$store.getters['toast/notification']
    },
    displayNotification: {
      get () { return !!this.notification.content },
      set () {
        this.$store.commit('toast/clear')
      }
    }
  }
  // mounted () {
  //   // console.log('Component mounted')
  //   this.$store.watch(
  //     (state, getters) => getters['notification/notification'],
  //     function () {
  //       this.$data.displayNotification = true
  //     }
  //   )
  // }
}
</script>
