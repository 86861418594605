<template>
  <v-app>
    <!-- <AppBar /> -->
    <v-app-bar
      app
      hide-on-scroll
    >
      <div class="d-flex align-center">
        <router-link to="/">
          <v-scroll-y-reverse-transition v-if="isOnPC">
            <v-img
              v-show="!hideLogo"
              contain
              src="@/assets/img/logo/app-logo.png"
              width="130"
              class="mt-10"
            />
          </v-scroll-y-reverse-transition>
        </router-link>
        <v-scroll-y-reverse-transition v-if="!isOnPC">
          <v-img
            v-show="!hideLogo"
            contain
            src="@/assets/img/logo/app-logo.png"
            width="100"
            class="mt-10"
            @click="navbarIsOpen = !navbarIsOpen"
          />
        </v-scroll-y-reverse-transition>
        <div class="d-flex" v-if="isOnPC">
          <gallery-button text color="primary" :items="destinationCategories">
            Destinations
            <template v-slot:linker>
              <div class="d-flex text-center">
                <v-btn color="primary" text to="/destinations">See all destinations</v-btn>
              </div>
            </template>
          </gallery-button>
          <v-btn color="primary" text to="/articles">Articles</v-btn>
          <v-btn color="primary" text to="/restaurants/">Food &amp; Drinks</v-btn>
        </div>
      </div>
      <v-spacer />
      <div class="d-flex justify-center align-center fill-height">
        <login-component />
      </div>

    </v-app-bar>

    <navigation-bar v-model="navbarIsOpen" v-if="!isOnPC" />

    <v-main v-scroll="onScroll">
      <router-view id="content" :key="`${$route.name}${JSON.stringify($route.params)}`"/>
    </v-main>

    <toast-notification />
  </v-app>
</template>

<script>
import GalleryWithButton from '@/components/GalleryWithButton'
import NavBar from './components/NavBar'
import LoginDialog from './components/LoginDialog'

// import AppBar from '@/components/AppBar/AppBar'

import ToastNotification from './components/ToastNotification'

import firebase from './plugins/firebase'

export default {
  name: 'App',

  components: {
    'login-component': LoginDialog,
    'gallery-button': GalleryWithButton,
    'navigation-bar': NavBar,
    'toast-notification': ToastNotification
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    },

    navbarIsOpen: {
      get () {
        return this.$store.state.navbarIsOpen
      },
      set (v) {
        this.$store.commit('setNavbarState', v)
      }
    },

    destinationCategories () {
      return this.$store.state.destinationCategories
    },

    authenticatedUser () {
      return this.$store.getters['auth/user']
    },

    userCredentials () {
      return this.$store.getters['auth/credentials']
    },

    scrollPosition: {
      get () {
        return this.$store.state.scrollPosition
      },

      set (v) {
        this.$store.commit('setScroll', v)
      }
    }
  },

  data: () => ({
    loading: false
    // navbarIsOpen: false
  }),

  async created () {
    // Grab displayed data
    this.$data.loading = true

    // this.$store.commit('setDestinationCategories', promisesResults[0].value)
    this.$data.loading = false

    // Check login status
    this.listenForAuthState()

    // If redirected from login, check redirect
    this.handleAuthRedirect()
  },

  methods: {
    onScroll (e) {
      var newScroll = e.currentTarget.document.documentElement.scrollTop
      this.$data.hideLogo = this.$data.scrollPosition <= newScroll
      this.$data.scrollPosition = e.currentTarget.document.documentElement.scrollTop
    },

    listenForAuthState () {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          this.$store.commit('auth/setUser', user)
        } else {
          this.$store.commit('auth/destroyUser')
          this.$store.commit('auth/destroyCredentials')
        }
        this.$store.commit('auth/changeLoadingState', false)
      })
    },
    handleAuthRedirect () {
      firebase.auth().getRedirectResult().then(result => {
        // if (result.credential) this.$store.commit('auth/setToken', result.credential.accessToken)
        // if (result.user) this.$store.commit('auth/setUser', result.user)
        // this.$store.commit('auth/changeLoadingState', false)
        // console.log(result)
        if (result.credential) this.$store.commit('auth/setCredentials', result.credential)
      })
    }
  }
}
</script>
