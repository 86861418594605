import Vue from 'vue'
import VueRouter from 'vue-router'

// import firebase from '@/plugins/firebase'

import store from '@/store'

/* Secondary route imports */
import restaurantManagementRoutes from './restaurantManagementRoutes'

Vue.use(VueRouter)

const basicRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login')
  },
  {
    path: '/legal',
    name: 'Legal',
    component: () => import('../views/Legal')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout')
  },
  {
    path: '/covid-19',
    name: 'Covid',
    component: () => import('../views/Covid')
  },
  {
    path: '/destinations',
    name: 'DestinationList',
    component: () => import('../views/DestinationList'),
    props: true
  },
  {
    path: '/destinations/categories/:category',
    name: 'DestinationCategory',
    component: () => import('../views/DestinationCategoryViewer'),
    props: true
  },
  {
    path: '/destinations/:destinationId/:url',
    name: 'DestinationViewerWithUrl',
    component: () => import('../views/DestinationViewer'),
    props: true
  },
  {
    path: '/destinations/:destinationId/',
    name: 'DestinationViewer',
    component: () => import('../views/DestinationViewer'),
    props: true
  },
  {
    path: '/articles',
    name: 'BlogList',
    component: () => import('../views/BlogList')
  },
  {
    path: '/articles/:articleId',
    name: 'BlogViewer',
    component: () => import('../views/BlogView'),
    props: true
  },
  {
    path: '/articles/:articleId/:url',
    name: 'BlogViewerWithUrl',
    component: () => import('../views/BlogView'),
    props: true
  },
  {
    path: '/marketplace',
    name: 'MarketplaceView',
    component: () => import('../views/Marketplace')
  },
  {
    path: '/marketplace/:vendorId/:vendorName',
    name: 'VendorViewWithName',
    component: () => import('../views/VendorView'),
    props: true
  },
  {
    path: '/restaurants',
    name: 'RestaurantList',
    component: () => import('../views/Restaurants')
  },
  {
    path: '/restaurants/:restaurantId',
    name: 'RestaurantView',
    component: () => import('../views/RestaurantView'),
    props: true,
    alias: '/restaurants/:restaurantId/:restaurantUrl'
  },
  {
    path: '/restaurants/:restaurantId/menu/:menuId',
    name: 'RestaurantMenuItemView',
    component: () => import('../views/RestaurantMenuView'),
    props: true,
    alias: [
      '/restaurants/:restaurantId/menu/:menuId/:menuName',
      '/restaurants/:restaurantId/:restaurantName/menu/:menuId',
      '/restaurants/:restaurantId/:restaurantName/menu/:menuId/:menuName'
    ]
  }
]

const routes = [...basicRoutes, ...restaurantManagementRoutes]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  var routeRequiresAuth = to.matched.some(record => record.meta.requiresAuth)
  var loggedIn = !!store.getters['auth/user']

  if (routeRequiresAuth && !loggedIn) { return next({ name: 'Login' }) } else { return next() }
})

export default router
