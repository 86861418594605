<template>
  <v-menu offset-y>
    <template v-slot:activator="menuScope">
      <v-btn fab text color="white" v-on="menuScope.on">
        <v-avatar size="36">
          <v-img :src="user.photoURL" v-if="user.photoURL" />
          <span v-text="initials" v-else />
        </v-avatar>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title v-text="user.displayName" />
          <v-list-item-subtitle v-text="user.email" />
        </v-list-item-content>
        <v-list-item-action>
          <v-tooltip bottom>
            <template v-slot:activator="logoutTooltipScope">
              <v-btn @click="logout" v-on="logoutTooltipScope.on" icon><v-icon>mdi-logout</v-icon></v-btn>
            </template>
            <span>Logout</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
      <resto-management-link />
    </v-list>
  </v-menu>
</template>

<script>
// import UserProfileButton from './UserProfileButton'
import firebase from '../../../plugins/firebase'
import RestaurantManagementLinkVue from './RestaurantManagementLink.vue'

export default {
  name: 'UserProfilePanel',

  components: {
    // 'profile-button': UserProfileButton
    'resto-management-link': RestaurantManagementLinkVue
  },

  computed: {
    user () { return this.$store.getters['auth/user'] },
    initials () {
      var names = this.user === null ? null : this.user.displayName.split(' ')
      return `${names[0].substring(0, 1)}${names[names.length - 1].substring(0, 1)}`
    },
    userLoading () { return this.$store.getters.auth.loading }
  },

  methods: {
    logout () {
      firebase.auth().signOut().then(_ => {
        this.$router.push('/logout')
      })
    }
  }
}
</script>
