import Vue from 'vue'
import Vuex from 'vuex'
// import VuexPersistence from 'vuex-persist'
import createPersistedState from 'vuex-persistedstate'

import auth from './auth'
import toast from './toast-notification'
import restoManage from './resto-management'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: { auth, toast, restoManage },
  // plugins: [vuexPersist.plugin],
  state: {
    navbarIsOpen: false,
    destinationCategories: [],
    scrollPosition: 0,
    dialogIsOpen: false
  },
  mutations: {
    setNavbarState (state, v) {
      state.navbarIsOpen = v
    },
    setDestinationCategories (state, v) {
      state.destinationCategories = v
    },
    setScroll (state, v) {
      state.scrollPosition = v
    },
    setDialogStateOpen (state, v) {
      state.dialogIsOpen = v
    }
  },
  actions: {
  }
})
