export default {
  namespaced: true,
  state: {
    user: null,
    token: null,
    credentials: null,
    loading: true
  },
  getters: {
    user (state) {
      return state.user === null ? null : Object.assign({}, state.user)
    },
    userToken (state) {
      return state.token
    },
    credentials (state) {
      return state.credentials
    },
    userLoading (state) {
      return state.loading
    }
  },
  mutations: {
    changeLoadingState (state, v) {
      state.loading = v
    },
    setUser (state, v) {
      state.user = v
    },
    setToken (state, v) {
      state.token = v
    },
    setCredentials (state, v) {
      state.credentials = v
    },
    destroyCredentials (state) {
      state.credentials = null
    },
    destroyUser (state) {
      state.user = null
    },
    destroyToken (state) {
      state.token = null
    }
  }
}
