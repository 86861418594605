<template>
  <v-navigation-drawer dark class="navigation-bar" v-model="navBarIsOpen" app>
    <v-img width="100%" height="100" class="my-3" contain src="@/assets/img/logo/app-logo-transparent.png" />
    <v-divider class="mb-3" inset/>
    <v-list subheader dense nav>
      <v-list-item link to="/">
        <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/destinations">
        <v-list-item-icon><v-icon>mdi-map-marker</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Destinations</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link to="/restaurants">
        <v-list-item-icon><v-icon>mdi-silverware</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Food and Drink</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item link to="/travel">
        <v-list-item-icon><v-icon>mdi-airplane</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Travel</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link to="/shopping">
        <v-list-item-icon><v-icon>mdi-gift</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Shopping</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-item link to="/articles">
        <v-list-item-icon><v-icon>mdi-newspaper</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Articles</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    }
  },

  computed: {
    navBarIsOpen: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>

<style scoped>
.navigation-bar {
  color: #fe4940
}
</style>
