<!--
## GALLERY WITH BUTTON

A button that activates a menu grid with a bunch of thumbnails.
Each thumbnail is adorned with white text on a transparent-to-black gradient.

# Props
_items_
The collection of items in the gallery.
Contains the link, the image, alt, and the text of the link
-->

<template>
  <v-menu offset-overflow allow-overflow transition="scale-transition">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" :color="color" :text="text"><slot /><v-icon>mdi-chevron-down</v-icon></v-btn>
    </template>
    <v-card class="fill-height" width="750px">
      <v-card-title><slot /></v-card-title>
      <v-card-text class="scroll">
        <v-container class="fill-height pa-0 ma-0">
          <v-row dense>
            <v-col cols="4" v-for="item in items" :key="item.name">
              <router-link :to="item.link" class="no-underline">
                <v-img :alt="item.alt" :aspect-ratio="16 / 9" class="align-end fill-height" :src="item.image">
                  <div class="lightbox no-underline white--text text-no-wrap title px-1">{{item.name}}</div>
                </v-img>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="hasLinker">
        <slot name="linker" />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    items: Array,
    color: {
      type: String,
      default () { return 'primary' }
    },
    text: {
      type: Boolean,
      default: () => false
    }
  },

  computed: {
    hasLinker () {
      return !!this.$slots.linker
    }
  }
}
</script>

<style scoped>
.lightbox {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.no-underline {
  text-decoration-line: none
}

.scroll {
  overflow-y: auto
}
</style>
